/* global gtag */
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import Landing from './Components/Landing';
import Navbar from './Utils/Navbar';
import Contact from './Components/Contact';
import AllCasesStudies from './Components/AllCasesStudies';
import FamilyLaw from './Assets/FamilyLaw';
import CriminalDefence from './Assets/CriminalDefence';
import BusinessLaw from './Assets/BusinessLaw';
import ImmigrationLaw from './Assets/ImmigrationLaw';
import Footer from './Utils/Footer';
import PrivacyPolicy from './Utils/PrivacyPolicy';
import TermsConditions from './Utils/TermsConditions';
import Accessibility from './Utils/Accessibility';
import PropertyAuditing from './Assets/PropertyAuditing';
import StudentLaws from './Assets/StudentLaws';
import AccidentalLaw from './Assets/AccidentalLaws';
import ImageGallery from './Images/ImageGallery';
import AddUpdate from './Components/AddUpdate';
import LoginRegister from './admin/LoginRegister';
import BoardRevenue from './Assets/BoardRevenue';
import FamilyDispute from './Assets/FamilyDispute';
import MatrimonialCases from './Assets/MatrimonialCases';
import GST from './Assets/GST';
import IncomeTax from './Assets/IncomeTax';
import TradeMark from './Assets/TradeMark';
import DirectTax from './Assets/DirectTax';
import IndirectTax from './Assets/IndirectTax';
import PageNotFound from './PageNotFound';

axios.defaults.withCredentials = true;

function App() {
  const handleButtonClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
    gtag_report_conversion(window.location.href);
  };

  const [login, setLogin] = useState(null);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    axios.get('https://api.legalbrother.in/api/protectedRoute')
      .then(res => {
        if (res.data.error !== "Unauthorized") {
          setLogin(true);
          setMsg(false);
        } else {
          setLogin(false);
          setMsg(res.data.error);
        }
      })
      .catch(err => {
        setLogin(false);
        setMsg(err.message);
      });
  }, []);

  function gtag_report_conversion(url) {
    const callback = function () {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      'send_to': 'AW-11453371061/IJ2CCJSIqoQaELWlstUq',
      'event_callback': callback
    });
    return false;
  }

  return (
    <div className='bg-black' style={{ minWidth: "380px" }}>
      <button className='btn slider text-white' onClick={() => handleButtonClick(8172846811)} style={{ background: "#D90700" }}>
        <div>
          <b>
            <i className="bi bi-telephone-fill"></i>
            &nbsp;&nbsp;<strong>CALL NOW</strong>
          </b>
          <br className='br-in-slider' />
          <span className='Number'>+91-8172846811</span>
        </div>
      </button>
      <Navbar />
      <div className="App" style={{ minHeight: "80vh" }}>
        <Router>
          <Helmet>
            <title>Legal Brothers</title>
            <meta name='description' content='At Legal Brothers, we are committed to delivering exceptional legal services to our clients...' />
            <meta name='keywords' content='Legal Brothers, Court Cases, Family Cases...' />
          </Helmet>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path='/contact-us' element={<Contact />} />
            <Route exact path="/all-case-study" element={<AllCasesStudies />} />
            <Route exact path="/gst" element={<GST />} />
            <Route exact path="/direct-tax" element={<DirectTax />} />
            <Route exact path="/indirect-tax" element={<IndirectTax />} />
            <Route exact path="/income-tax" element={<IncomeTax />} />
            <Route exact path="/trademark" element={<TradeMark />} />
            <Route exact path="/family-law" element={<FamilyLaw />} />
            <Route exact path="/family-dispute" element={<FamilyDispute />} />
            <Route exact path="/matrimonial-cases" element={<MatrimonialCases />} />
            <Route exact path="/criminal-defense" element={<CriminalDefence />} />
            <Route exact path="/business-law" element={<BusinessLaw />} />
            <Route exact path="/immigration-law" element={<ImmigrationLaw />} />
            <Route exact path="/property-auditing-law" element={<PropertyAuditing />} />
            <Route exact path="/student-laws" element={<StudentLaws />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-conditions" element={<TermsConditions />} />
            <Route exact path="/accessibility" element={<Accessibility />} />
            <Route exact path="/accidental-laws" element={<AccidentalLaw />} />
            <Route exact path="/revenue-board" element={<BoardRevenue />} />
            <Route exact path="/Gallery" element={<ImageGallery />} />
            <Route exact path="/auth/isValid/:auth/add-update" element={<AddUpdate />} />
            <Route exact path="/auth/admin/login&register" element={<LoginRegister />} />
            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
