import React from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
axios.defaults.withCredentials = true;

const DirectTax = () => {
    return (
        <div className="py-2 bg-white">
            <Helmet>
                <title>Legal Brothers - Direct Tax Legal</title>
                <meta
                    name="description"
                    content="Learn about direct tax legal matters including types of taxes, laws, compliance, disputes, penalties, and planning with Legal Brothers."
                />
                <meta
                    name="keywords"
                    content="Legal Brothers, Direct Tax, Income Tax, Wealth Tax, Tax Planning, Tax Compliance"
                />
            </Helmet>
            <div
                className="mt-4 pt-3 pb-1 container"
                style={{ background: 'rgb(0 0 0 / 5%)', height: '100%' }}
            >
                <h1>
                    <center
                        className="fs-1 fw-bolder border-bottom py-3 border-3"
                        style={{ color: 'rgb(217, 7, 0)' }}
                    >
                        Direct Tax Legal
                    </center>
                </h1>
                <div className="container text-dark py-4" style={{ height: '' }}>
                    <div className="fs-5">
                        Direct tax legal matters involve laws and regulations governing taxes levied
                        directly on individuals and businesses.
                    </div>
                    <hr />
                    <div>
                        <b>Here are key aspects:</b>
                    </div>

                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            01. &nbsp;&nbsp;Types of Direct Taxes
                        </div>
                        <div className="px-5">
                            <b>1. Income Tax:</b> Tax on an individual's or business's income or
                            profits.
                        </div>
                        <div className="px-5">
                            <b>2. Wealth Tax:</b> Tax on an individual's net wealth or assets.
                        </div>
                        <div className="px-5">
                            <b>3. Gift Tax:</b> Tax on gifts or transfers of assets.
                        </div>
                        <div className="px-5">
                            <b>4. Estate Tax:</b> Tax on the transfer of a deceased person's estate.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            02. &nbsp;&nbsp;Direct Tax Laws and Regulations
                        </div>
                        <div className="px-5">
                            <b>1. Income-tax Act:</b> The primary legislation governing income tax in
                            many countries.
                        </div>
                        <div className="px-5">
                            <b>2. Tax Codes:</b> Comprehensive laws outlining tax rules and
                            regulations.
                        </div>
                        <div className="px-5">
                            <b>3. Tax Treaties:</b> Agreements between countries to avoid double
                            taxation.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            03. &nbsp;&nbsp;Direct Tax Compliance
                        </div>
                        <div className="px-5">
                            <b>1. Tax Returns:</b> Filing annual tax returns to report income and
                            claim deductions.
                        </div>
                        <div className="px-5">
                            <b>2. Tax Payments:</b> Making timely tax payments to avoid penalties
                            and interest.
                        </div>
                        <div className="px-5">
                            <b>3. Tax Audits:</b> Cooperating with tax authorities during audits and
                            providing required documentation.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            04. &nbsp;&nbsp;Direct Tax Disputes and Litigation
                        </div>
                        <div className="px-5">
                            <b>1. Tax Appeals:</b> Challenging tax assessments or decisions through
                            administrative appeals.
                        </div>
                        <div className="px-5">
                            <b>2. Tax Litigation:</b> Pursuing tax disputes through the courts.
                        </div>
                        <div className="px-5">
                            <b>3. Alternative Dispute Resolution (ADR):</b> Using mediation,
                            arbitration, or other ADR methods to resolve tax disputes.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            05. &nbsp;&nbsp;Direct Tax Planning and Advisory
                        </div>
                        <div className="px-5">
                            <b>1. Tax Planning:</b> Strategically managing taxes to minimize
                            liability and maximize benefits.
                        </div>
                        <div className="px-5">
                            <b>2. Tax Advisory:</b> Seeking professional advice on tax matters,
                            including compliance and planning.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            06. &nbsp;&nbsp;Penalties and Consequences
                        </div>
                        <div className="px-5">
                            <b>1. Penalties:</b> Fines and penalties for non-compliance or tax
                            evasion.
                        </div>
                        <div className="px-5">
                            <b>2. Interest:</b> Paying interest on unpaid taxes or tax debts.
                        </div>
                        <div className="px-5">
                            <b>3. Prosecution:</b> Facing criminal charges for tax evasion or
                            serious non-compliance.
                        </div>
                    </div>

                    <hr />
                    <div className="px-5">
                        Understanding and complying with direct tax laws is crucial for avoiding
                        legal consequences and optimizing financial planning.
                    </div>
                    <hr />
                    <center>
                        <a href='/income-tax' className='btn text-white' style={{ background: "#D90700" }}>More About Income Tax . .</a>
                    </center>
                    <hr />
                </div>
            </div>
        </div>
    );
};

export default DirectTax;
