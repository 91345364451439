import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
axios.defaults.withCredentials = true
const GST = () => {
    return (
        <div className='py-2 bg-white'>
            <Helmet>
                <title>Legal Brothers - Gst and Income Tax </title>
                <meta name='description' content='Explore expert guidance on family laws with Legal Brothers. Navigate court and family cases, including child custody matters. Your trusted partner in legal advocacy' />
                <meta name='keywords' content='Legal Brothers, Court Cases, Family Cases,  Child Custody' />
            </Helmet>
            <div className='mt-4 pt-3 pb-1 container' style={{ background: "rgb(0 0 0 / 5%)", height: "100%" }}>
                <h1> <center className='fs-1 fw-bolder border-bottom py-3 border-3' style={{ color: "rgb(217, 7, 0)" }}>Gst and Income Tax</center></h1>
                <div className='container text-dark py-4' style={{ height: "" }}>
                    <div className='fs-5'>
                        GST (Goods and Services Tax) is a complex tax regime with various legal implications.
                    </div>
                    <hr />
                    <div>
                        <b>Here's an overview of key GST legal matters:                        </b>
                    </div>
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>01. &nbsp;&nbsp; GST Legal Framework
                        </div>
                        <div className='px-5 '>
                            <b>1. GST Act:
                            </b>
                            The Central Goods and Services Tax Act, 2017, and the Integrated Goods and Services Tax Act, 2017, form the foundation of the GST legal framework.
                        </div>
                        <div className='px-5 '>
                            <b>2. GST Rules:
                            </b>
                            The GST Rules, 2017, provide detailed procedures and guidelines for GST implementation.

                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>02. &nbsp;&nbsp;Seeking Professional Advice</div>
                        <div className='px-5 '>
                            <b>1. Consult a lawyer: </b>
                            Expert legal advice can help navigate the situation.
                        </div>
                        <div className='px-5 '>
                            <b>3. Notifications and Circulars:
                            </b>
                            The GST Council and the Central Board of Indirect Taxes and Customs (CBIC) issue notifications and circulars to clarify and amend GST provisions.
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Key GST Legal Aspects
                        </div>
                        <div className='px-5 '>
                            <b>1. Registration:
                            </b>
                            GST registration is mandatory for businesses with a turnover exceeding the prescribed threshold.
                        </div>
                        <div className='px-5 '>
                            <b>2. Invoicing and Accounting:
                            </b>
                            GST invoices must contain specific details, and businesses must maintain accurate accounting records.
                        </div>
                        <div className='px-5 '>
                            <b>3. Tax Payment and Returns: </b>
                            GST tax payments and returns must be filed within the prescribed timelines.
                        </div>
                        <div className='px-5 '>
                            <b>4. Input Tax Credit (ITC):  </b>
                            ITC is available for eligible inputs, but there are specific conditions and restrictions.
                        </div>
                        <div className='px-5 '>
                            <b>5. GST Audit and Assessment:  </b>
                            The GST authorities can conduct audits and assessments to ensure compliance.
                        </div>
                    </div>
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;GST Litigation and Dispute Resolution
                        </div>
                        <div className='px-5 '>
                            <b>1. GST Tribunal:  </b>
                            The GST Tribunal is the primary forum for resolving GST disputes.
                        </div>
                        <div className='px-5 '>
                            <b>2. High Courts and Supreme Court:  </b>GST matters can be appealed to the High Courts and the Supreme Court.

                        </div>
                        <div className='px-5 '>
                            <b>3. Alternative Dispute Resolution (ADR):  </b>
                            ADR mechanisms, such as mediation and arbitration, can be used to resolve GST disputes.
                        </div>
                    </div>
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Compliance and Penalties

                        </div>
                        <div className='px-5 '>
                            <b>1. GST Compliance Rating:    </b>
                            Businesses are assigned a GST compliance rating based on their compliance record.

                        </div>
                        <div className='px-5 '>
                            <b>2. Penalties and Fines:  </b>
                            Non-compliance with GST provisions can result in penalties, fines, and even prosecution.

                        </div>
                        <div className='px-5 '>
                            <b>3. Focus on resolution: </b>
                            Prioritize finding a resolution over "winning" the dispute.
                        </div>
                    </div>
                    <hr />
                    <div>
                        {/* <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>04. &nbsp;&nbsp;Divorce</div> */}
                        <div className='px-5 '>
                            It is essential for businesses to stay up-to-date with GST laws, rules, and regulations to ensure compliance and avoid legal issues.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GST