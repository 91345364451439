import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
axios.defaults.withCredentials = true
const MatrimonialCases = () => {
  return (
    <div className='py-2 bg-white'>
      <Helmet>
        <title>Legal Brothers - Matrimonial Cases </title>
        <meta name='description' content='Explore expert guidance on family laws with Legal Brothers. Navigate court and family cases, including child custody matters. Your trusted partner in legal advocacy' />
        <meta name='keywords' content='Legal Brothers, Court Cases, Family Cases,  Child Custody' />
      </Helmet>
      <div className='mt-4 pt-3 pb-1 container' style={{ background: "rgb(0 0 0 / 5%)", height: "100%" }}>
        <h1> <center className='fs-1 fw-bolder border-bottom py-3 border-3' style={{ color: "rgb(217, 7, 0)" }}>Matrimonial Laws</center></h1>
        <div className='container text-dark py-4' style={{ height: "" }}>
          <div className='fs-5'>
            Matrimonial cases involve complex legal and emotional issues. Here's an overview of key aspects:
          </div>
          <hr />
          <div>
            <b> Here's an overview of key aspects:           </b>
          </div>
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>01. &nbsp;&nbsp; Types of Matrimonial Cases -              </div>
            <div className='px-5 '>
              <b>1. Divorce: </b>
              Dissolution of marriage, either contested or uncontested.
            </div>
            <div className='px-5 '>
              <b>2. Child Custody:  </b>
              Determining parental rights and responsibilities.
            </div>
          </div>
          <hr />
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>02. &nbsp;&nbsp;Seeking Professional Advice</div>
            <div className='px-5 '>
              <b>1. Consult a lawyer: </b>
              Expert legal advice can help navigate the situation.
            </div>
            <div className='px-5 '>
              <b>2. Mediation or counseling: </b>
              Consider alternative dispute resolution methods.
            </div>
            <div className='px-5 '>
              <b>3. Child Support: </b>
              Establishing financial support for children.
            </div>
            <div className='px-5 '>
              <b>4. Spousal Support: </b>
              Providing financial support to a former spouse.
            </div>
            <div className='px-5 '>
              <b>5. Property Division: </b>
              Distributing marital assets and debts.

            </div>
          </div>
          <hr />
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Legal Processes
            </div>
            <div className='px-5 '>
              <b>1. Filing a Petition:   </b>
              Initiating the legal process with a court filing.
            </div>
            <div className='px-5 '>
              <b>2. Serving Documents:  </b>
              Notifying the other party of the legal action.
            </div>
            <div className='px-5 '>
              <b>3. Discovery:  </b>
              Exchanging information and evidence.
            </div>
            <div className='px-5 '>
              <b>4. Mediation:   </b>
              Attempting to resolve disputes through negotiation.
            </div>
            <div className='px-5 '>
              <b>5. Trial:    </b>
              Presenting evidence and arguments to a judge or jury.
            </div>
          </div>
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Important Considerations
            </div>
            <div className='px-5 '>
              <b>1. Jurisdiction:  </b>
              Ensuring the court has authority to hear the case.
            </div>
            <div className='px-5 '>
              <b>2. Grounds for Divorce:  </b>
              Prioritize emotional and physical well-being.
            </div>
            <div className='px-5 '>
              <b>3. Best Interests of the Child:  </b>
              Prioritizing the child's well-being.
            </div>
            <div className='px-5 '>
              <b>4. Financial Disclosure:   </b>
              Providing accurate and complete financial information.
            </div>
            <div className='px-5 '>
              <b>5. Seeking Professional Advice: </b>
              Consulting with an experienced family law attorney.
            </div>
          </div>
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Emotional Well-being
            </div>
            <div className='px-5 '>
              <b>1. Counseling:  </b>
              Seeking professional help to cope with emotional challenges.
            </div>
            <div className='px-5 '>
              <b>2. Support Network:   </b>
              Surrounding yourself with caring family and friends.
            </div>
            <div className='px-5 '>
              <b>3. Self-Care: </b>
              Prioritizing physical and emotional well-being.
            </div>
            <div className='px-5 '>
              <b>4. Setting Boundaries:</b>
              Establishing clear limits to manage conflict.
            </div>
            <div className='px-5 '>
              <b>5. Focusing on the Future: </b>
              Prioritizing personal growth and moving forward.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MatrimonialCases