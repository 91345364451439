import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
axios.defaults.withCredentials = true
const FamilyDispute = () => {
  return (
    <div className='py-2 bg-white'>
      <Helmet>
        <title>Legal Brothers - Family Disputes </title>
        <meta name='description' content='Explore expert guidance on family laws with Legal Brothers. Navigate court and family cases, including child custody matters. Your trusted partner in legal advocacy' />
        <meta name='keywords' content='Legal Brothers, Court Cases, Family Cases,  Child Custody' />
      </Helmet>
      <div className='mt-4 pt-3 pb-1 container' style={{ background: "rgb(0 0 0 / 5%)", height: "100%" }}>
        <h1> <center className='fs-1 fw-bolder border-bottom py-3 border-3' style={{ color: "rgb(217, 7, 0)" }}>Family Dispute</center></h1>
        <div className='container text-dark py-4' style={{ height: "" }}>
          <div className='fs-5'>
            Family disputes can be emotionally challenging and complex. When legal matters are involved, it's essential to approach the situation carefully.
          </div>
          <hr />
          <div>
            <b>Here are some steps to consider : </b>
          </div>
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>01. &nbsp;&nbsp; Identifying the Issue            </div>
            <div className='px-5 '>
              <b>1-  Clarify the dispute: </b>
              Understand the core issue driving the conflict.
            </div>
            <div className='px-5 '>
              <b>2. Gather information: </b>
              Collect relevant documents, records, and evidence.</div>
          </div>
          <hr />
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>02. &nbsp;&nbsp;Seeking Professional Advice</div>
            <div className='px-5 '>
              <b>1. Consult a lawyer: </b>
              Expert legal advice can help navigate the situation.
            </div>
            <div className='px-5 '>
              <b>2. Mediation or counseling: </b>
              Consider alternative dispute resolution methods.
            </div>
          </div>
          <hr />
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Resolving the Dispute
            </div>
            <div className='px-5 '>
              <b>1. Negotiation:  </b>
              Attempt to reach a mutually acceptable agreement.
            </div>
            <div className='px-5 '>
              <b>2. Mediation: </b>
              A neutral third-party facilitates a resolution.
            </div>
            <div className='px-5 '>
              <b>3. Litigation: </b>
              Pursue legal action through the courts.
            </div>
          </div>
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Emotional Well-being
            </div>
            <div className='px-5 '>
              <b>1. Support network:  </b>
              Surround yourself with caring family and friends.
            </div>
            <div className='px-5 '>
              <b>2. Self-care:  </b>
              Prioritize emotional and physical well-being.
            </div>
            <div className='px-5 '>
              <b>3. Professional counseling: </b>
              Seek guidance from a therapist or counselor.
            </div>
          </div>
          <div>
            <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Important Considerations
            </div>
            <div className='px-5 '>
              <b>1. Confidentiality:   </b>
              Maintain confidentiality to protect sensitive information.
            </div>
            <div className='px-5 '>
              <b>2. Respect boundaries:   </b>
              Establish clear boundaries to manage conflict.
            </div>
            <div className='px-5 '>
              <b>3. Focus on resolution: </b>
              Prioritize finding a resolution over "winning" the dispute.
            </div>
          </div>
          <hr />
          <div>
            {/* <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>04. &nbsp;&nbsp;Divorce</div> */}
            <div className='px-5 '>
              Remember, resolving family disputes involving legal matters requires patience, understanding, and a willingness to seek professional advice.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FamilyDispute