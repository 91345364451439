import React from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
axios.defaults.withCredentials = true;

const IndirectTax = () => {
    return (
        <div className="py-2 bg-white">
            <Helmet>
                <title>Legal Brothers - Indirect Tax Legal</title>
                <meta
                    name="description"
                    content="Explore indirect tax legal matters including types of taxes, laws, compliance, disputes, penalties, and planning with Legal Brothers."
                />
                <meta
                    name="keywords"
                    content="Legal Brothers, Indirect Tax, VAT, GST, Customs Duty, Tax Planning, Tax Compliance"
                />
            </Helmet>
            <div
                className="mt-4 pt-3 pb-1 container"
                style={{ background: 'rgb(0 0 0 / 5%)', height: '100%' }}
            >
                <h1>
                    <center
                        className="fs-1 fw-bolder border-bottom py-3 border-3"
                        style={{ color: 'rgb(217, 7, 0)' }}
                    >
                        Indirect Tax Legal
                    </center>
                </h1>
                <div className="container text-dark py-4" style={{ height: '' }}>
                    <div className="fs-5">
                        Indirect tax legal matters involve laws and regulations governing taxes levied on goods and services.
                    </div>
                    <hr />
                    <div>
                        <b>Here are key aspects:</b>
                    </div>

                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            01. &nbsp;&nbsp;Types of Indirect Taxes
                        </div>
                        <div className="px-5">
                            <b>1. Value-Added Tax (VAT):</b> Tax on the value added to goods and services.
                        </div>
                        <div className="px-5">
                            <b>2. Goods and Services Tax (GST):</b> Comprehensive tax on goods and services.
                        </div>
                        <div className="px-5">
                            <b>3. Excise Tax:</b> Tax on specific goods, such as tobacco or liquor.
                        </div>
                        <div className="px-5">
                            <b>4. Customs Duty:</b> Tax on imported goods.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            02. &nbsp;&nbsp;Indirect Tax Laws and Regulations
                        </div>
                        <div className="px-5">
                            <b>1. GST Act:</b> The primary legislation governing GST in many countries.
                        </div>
                        <div className="px-5">
                            <b>2. VAT Act:</b> Laws governing VAT, including rates, exemptions, and compliance.
                        </div>
                        <div className="px-5">
                            <b>3. Customs Act:</b> Regulations governing customs duty, including tariffs and procedures.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            03. &nbsp;&nbsp;Indirect Tax Compliance
                        </div>
                        <div className="px-5">
                            <b>1. Registration:</b> Registering for indirect tax purposes, such as obtaining a GST or VAT number.
                        </div>
                        <div className="px-5">
                            <b>2. Returns:</b> Filing periodic tax returns to report indirect tax liabilities.
                        </div>
                        <div className="px-5">
                            <b>3. Payments:</b> Making timely tax payments to avoid penalties and interest.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            04. &nbsp;&nbsp;Indirect Tax Disputes and Litigation
                        </div>
                        <div className="px-5">
                            <b>1. Tax Audits:</b> Cooperating with tax authorities during audits and providing required documentation.
                        </div>
                        <div className="px-5">
                            <b>2. Tax Appeals:</b> Challenging tax assessments or decisions through administrative appeals.
                        </div>
                        <div className="px-5">
                            <b>3. Tax Litigation:</b> Pursuing tax disputes through the courts.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            05. &nbsp;&nbsp;Indirect Tax Planning and Advisory
                        </div>
                        <div className="px-5">
                            <b>1. Tax Planning:</b> Strategically managing indirect taxes to minimize liability and maximize benefits.
                        </div>
                        <div className="px-5">
                            <b>2. Tax Advisory:</b> Seeking professional advice on indirect tax matters, including compliance and planning.
                        </div>
                    </div>

                    <hr />
                    <div>
                        <div className="fs-4 fw-semibold" style={{ color: 'rgb(217, 7, 0)' }}>
                            06. &nbsp;&nbsp;Penalties and Consequences
                        </div>
                        <div className="px-5">
                            <b>1. Penalties:</b> Fines and penalties for non-compliance or tax evasion.
                        </div>
                        <div className="px-5">
                            <b>2. Interest:</b> Paying interest on unpaid taxes or tax debts.
                        </div>
                        <div className="px-5">
                            <b>3. Prosecution:</b> Facing criminal charges for tax evasion or serious non-compliance.
                        </div>
                    </div>

                    <hr />
                    <div className="px-5">
                        Indirect tax laws and regulations can be complex and nuanced. It's essential to seek professional advice to ensure compliance and optimize tax planning.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndirectTax;
