import React, { useEffect, useState, useTransition } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
axios.defaults.withCredentials = true
const ImageGallery = () => {
    const [images, setImages] = useState([])
    const [size, setSize] = useState(true)
    const [close, setClose] = useState(false)
    const [title, setTitle] = useState()
    const [file, setFile] = useState(null);
    const [msg, setMsg] = useState(null)
    const [msg_type, setMsg_type] = useState(null)
    const [login, setLogin] = useState(false)
    useEffect(() => {
        // axios.get('http://localhost:8096/api/protectedRoute')
        axios.get('https://api.legalbrother.in/api/protectedRoute')
            .then(res => {
                setLogin(res.data.login)
            })
            .catch(err => console.log(err))
    })
    useEffect(() => {
        // axios.get('http://localhost:8096/api/getAllGallary')
        axios.get('https://api.legalbrother.in/api/getAllGallary')
            .then(res => {
                if (res.data.updates.length > 0)
                    setImages(res.data.updates)
                else setSize(false)
            })
            .catch(err => {
                console.log(err)
                setSize(false)
            })
    }, [])


    const submitAdd = async (e) => {
        e.preventDefault();
    
        if (!title || !file) {
            alert("Please provide both title and file.");
            return;
        }
    
        const formData = new FormData();
        formData.append("file", file); // Field name matches backend's multer config
        formData.append("title", title);
    
        try {
            console.log("FormData Entries:");
            for (const pair of formData.entries()) {
                console.log(pair[0] + ": " + pair[1]); // Log all form entries
            }
    
            const response = await axios.post(
                `https://api.legalbrother.in/api/saveUpdate/${title}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
    
            alert(response.data.msg);
            if (response.data.success) {
                window.location.reload(true);
            }
        } catch (err) {
            console.error("Error during file upload:", err.response?.data || err);
            alert(`Error uploading file: ${err.response?.data?.message || err.message}`);
        }
    };
    

    // const submitAdd = async (e) => {
    //     e.preventDefault()
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('title', title);
    //     if (title === '' || !title) {
    //         alert("Enter all values. . .")
    //     }
    //     else {
    //         console.log(formData, file, title)
    //         // axios.post(`http://localhost:8096/api/saveUpdate/${title}`, formData, { title: title })
    //         axios.post(`https://api.legalbrother.in/api/saveUpdate/${title}`, formData)
    //             .then(res => {
    //                 if (res.data.error === "Unauthorized") {
    //                     alert("You are not authorized for uploading data. . .")
    //                 }
    //                 else if (res.data.msg === "Update saved successfully") {
    //                     alert(res.data.msg)
    //                     window.location.reload(true)
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log("err ", err)
    //                 alert(`Error uploading file: ${err.response?.data?.message || err.message}`);
    //             })
    //         // try {
    //         //     const response = await axios.post(
    //         //         `https://api.legalbrother.in/api/saveUpdate/${title}`,
    //         //         formData,
    //         //         {
    //         //             headers: {
    //         //                 "Content-Type": "multipart/form-data",
    //         //             },
    //         //         }
    //         //     );
    //         //     console.log("Upload success:", response.data);
    //         //     alert("Upload successful!");
    //         //     window.location.reload();
    //         // } catch (err) {
    //         //     console.error("Upload error:", err.response?.data || err);
    //         //     alert(`Error uploading file: ${err.response?.data?.message || err.message}`);
    //         // }
    //     }
    // }

    const handleDelete = (selectedResourceId) => {
        console.log(selectedResourceId)
        const confirm = window.confirm("Are you sure to delete this project? This is irreversible.")
        if (confirm) {
            // axios.delete(`http://localhost:8096/api/gallary/${selectedResourceId}`)
            axios.delete(`https://api.legalbrother.in/api/gallary/${selectedResourceId}`)
                .then(res => {
                    alert(res.data.msg)
                    setMsg(res.data.msg)
                    setMsg_type(res.data.msg_type)
                    setInterval(() => {
                        setMsg(null)
                    }, 2000)
                })
                .catch(err => console.log("Error -> ", err))
        }
    }
    return (
        <div className='min-vh-100 bg-light'>
            <Helmet>
                <meta name="description" content="See the reputated Advocates and get opportunity to connect with them." />
            </Helmet>
            <div className='container p-2'>
                <h1><center className='fs-1 fw-bolder border-bottom border-secondary mt-5 py-3 border-3' style={{ color: "rgb(217, 7, 0)" }}>Image Gallery</center></h1>
                {
                    login && <button className='addImage btn' onClick={e => setClose(!close)}>{close ? "Close Add / Show All" : "Add Image"}</button>
                }
                {
                    close ? <div className='container'>
                        <form className='my-3 p-2 border rounded bg-white shadow'>
                            <center><h3>Add New Images</h3></center>
                            <div className='form-group mb-3'>
                                <label className='mb-1'>Title</label>
                                <input type='text' className='form-control' placeholder='Name of App/website' onChange={e => setTitle(e.target.value)} required />
                            </div>
                            <div className='form-group mb-3'>
                                <label className='mb-1'>Image</label>
                                <input
                                    type='file'
                                    class='form-control'
                                    id='inputGroupFile01'
                                    required
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>
                            <button className='btn btn-success' onClick={submitAdd}>Submit</button>
                        </form>
                    </div> : <div className='row'>
                        {msg && <center>{msg}</center>}
                        {
                            size ? images.map((data, i) => (
                                <div key={i} className='col-sm-12 gx-3 gy-3  img-thumbnail col-md-6 col-lg-4 border col-xl-4 col-xxl-3 d-flex justify-content-center' style={{ background: "rgba(0, 0, 0, 0.2)", color: "white", boxShadow: "0px 5px 5px 2px #00000096" }}>
                                    <div>
                                        {/* <img src={`http://localhost:8096/file/${data.file}`} className='pt-2 rounded-4' style={{ maxWidth: "320px", maxHeight: "200px" }} /> */}
                                        <img src={`https://api.legalbrother.in/file/${data.file}`} className='pt-2 rounded-4' style={{ maxWidth: "320px", maxHeight: "200px" }} />
                                        {/* <hr /> */}
                                        <center className='fw-bold text-dark'>{data.title}</center>
                                        {login && <button className='btn btn-danger my-1 w-100' onClick={e => handleDelete(data.id)}>Delete</button>}
                                        <br />
                                    </div>
                                </div>
                            )) : <center className='alert alert-danger my-3'>No Data Available</center>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ImageGallery