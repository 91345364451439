import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
axios.defaults.withCredentials = true
const IncomeTax = () => {
    return (
        <div className='py-2 bg-white'>
            <Helmet>
                <title>Legal Brothers - Income Tax </title>
                <meta name='description' content='Explore expert guidance on family laws with Legal Brothers. Navigate court and family cases, including child custody matters. Your trusted partner in legal advocacy' />
                <meta name='keywords' content='Legal Brothers, Court Cases, Family Cases,  Child Custody' />
            </Helmet>
            <div className='mt-4 pt-3 pb-1 container' style={{ background: "rgb(0 0 0 / 5%)", height: "100%" }}>
                <h1> <center className='fs-1 fw-bolder border-bottom py-3 border-3' style={{ color: "rgb(217, 7, 0)" }}>Income Tax</center></h1>
                <div className='container text-dark py-4' style={{ height: "" }}>
                    <div className='fs-5'>
                        Income tax legal matters involve compliance with tax laws and regulations.
                    </div>
                    <hr />
                    <div>
                        <b> Here are key aspects:</b>
                    </div>
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>01. &nbsp;&nbsp;Tax Compliance

                        </div>
                        <div className='px-5 '>
                            <b>1. Filing Tax Returns:
                            </b>
                            Submitting accurate tax returns within the prescribed deadline.
                        </div>
                        <div className='px-5 '>
                            <b>2. Tax Payment:
                            </b>
                            Paying taxes due, including advance tax and self-assessment tax.
                        </div>
                        <div className='px-5 '>
                            <b>3. Tax Deduction at Source (TDS):
                            </b>
                            Deducting tax at source for specified transactions.
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>02. &nbsp;&nbsp;Tax Planning
                        </div>
                        <div className='px-5 '>
                            <b>1. Tax Exemptions: </b>
                            Claiming exemptions under various sections, such as Section 80C.

                        </div>
                        <div className='px-5 '>
                            <b>2. Tax Deductions:
                            </b>
                            Claiming deductions for expenses, such as charitable donations.
                        </div>
                        <div className='px-5 '>
                            <b>3. Tax Credits:
                            </b>
                            Claiming credits for taxes paid, such as foreign tax credits.
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>03. &nbsp;&nbsp;Tax Disputes
                        </div>
                        <div className='px-5 '>
                            <b>1. Assessment Proceedings:
                            </b>
                            Responding to tax notices and assessments.
                        </div>
                        <div className='px-5 '>
                            <b>2. Tax Appeals:
                            </b>
                            Filing appeals against tax assessments or orders.
                        </div>
                        <div className='px-5 '>
                            <b>3. Tax Litigation:  </b>
                            Representing cases in tax tribunals, High Courts, or the Supreme Court.
                        </div>
                    </div>
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>04. &nbsp;&nbsp;Tax Laws and Regulations
                        </div>
                        <div className='px-5 '>
                            <b>1. Income-tax Act, 1961: </b>
                            Understanding the Act's provisions, including tax rates and exemptions.
                        </div>
                        <div className='px-5 '>
                            <b>2. Income-tax Rules, 1962:   </b>Familiarity with rules governing tax compliance and procedures.

                        </div>
                        <div className='px-5 '>
                            <b>3. Tax Circulars and Notifications:  </b>
                            Staying updated on tax laws, amendments, and clarifications.
                        </div>
                    </div>
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>05. &nbsp;&nbsp;Tax Authorities
                        </div>
                        <div className='px-5 '>
                            <b>1. Income Tax Department:   </b>
                            Dealing with tax officials, including Assessing Officers and Tax Recovery Officers.
                        </div>
                        <div className='px-5 '>
                            <b>2. Central Board of Direct Taxes (CBDT):  </b>
                            Understanding CBDT's role in framing tax policies and guidelines.
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>06. &nbsp;&nbsp;Tax Professionals

                        </div>
                        <div className='px-5 '>
                            <b>1. Chartered Accountants (CAs):   </b>
                            Consulting CAs for tax advice, compliance, and representation.
                        </div>
                        <div className='px-5 '>
                            <b>2. Tax Consultants: </b>
                            Engaging tax consultants for specialized tax services.
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>07. &nbsp;&nbsp;Penalties and Consequences

                        </div>
                        <div className='px-5 '>
                            <b>1. Tax Penalties:  </b>
                            Facing penalties for non-compliance, including late filing fees and interest.
                        </div>
                        <div className='px-5 '>
                            <b>2. Prosecution: </b>
                            Risk of prosecution for tax evasion or willful non-compliance.

                        </div>
                    </div>
                    <hr />
                    <div>
                        {/* <div className='fs-4 fw-semibold' style={{ color: "rgb(217, 7, 0)" }}>04. &nbsp;&nbsp;Divorce</div> */}
                        <div className='px-5 '>
                        Staying informed about income tax laws and regulations is crucial for compliance and effective tax planning.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IncomeTax